import { useContext } from "react";
import { ToastContext } from "../../components/feedback/Toast/ToastContext";
import { addToast } from "../../components/feedback/Toast/ToastReducer";

const uuid = () =>
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

export interface ToastsHook {
    sendToast: (
        type: "question" | "info" | "warning" | "success" | "error",
        title: string,
        message: string,
        autoClose?: number
    ) => void;
}

const useToasts = (): ToastsHook => {
    const { dispatch } = useContext(ToastContext);

    const sendToast = (
        type: "question" | "info" | "warning" | "success" | "error",
        title: string,
        message: string,
        autoClose: number = 3000
    ) => {
        dispatch(addToast({ id: uuid(), type, title, message, autoClose }));
    };

    return { sendToast };
};

export default useToasts;

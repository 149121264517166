import React, { HTMLAttributes, isValidElement, cloneElement, ReactElement } from "react";
import { ButtonProps } from "../Button/Button";
import styles from "../Button/Button.module.css";

export interface ButtonGroupProps extends HTMLAttributes<HTMLDivElement> {
    /** Variant of the button. */
    variant?: "primary" | "secondary" | "tertiary";
    /** The buttons to display as a group. */
    children: ReactElement<ButtonProps> | Array<ReactElement<ButtonProps>>;
}

const ButtonGroup = ({ variant, className, children, ...other }: ButtonGroupProps) => {
    const classNames = [styles.group];
    if (className) classNames.push(className);

    return (
        <div className={classNames.join(" ")} {...other}>
            {React.Children.map(children, (child, index) => {
                if (isValidElement(child)) return cloneElement(child, { key: index, variant });
                return null;
            })}
        </div>
    );
};

export default ButtonGroup;
